import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { LANGS } from '@core/translate/translate.const';

@Injectable({ providedIn: 'root' })
export class AuthServicePages {
	private _serverUrl: string = environment.serverUrl;

	constructor(private _httpClient: HttpClient) {}

	// -----------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------

	/**
	 * Check if email is already register
	 *
	 * @param email
	 */
	checkEmail(email: { email: string }): Observable<any> {
		const url = `${this._serverUrl}/email_check`;
		return this._httpClient.post<any>(url, email);
	}

	/**
	 * Send recovery pass email
	 *
	 */
	sendEmailRecovery(data: any): Observable<any> {
		return this._httpClient.post(`${this._serverUrl}/password/email`, data);
	}

	/**
	 * Check recovery pass code
	 *
	 */
	checkCode(data: any): Observable<any> {
		return this._httpClient.post(`${this._serverUrl}/password/code_check`, data);
	}

	/**
	 * Reset pass
	 *
	 */
	resetPassword(data: any): Observable<any> {
		return this._httpClient.post(`${this._serverUrl}/password/reset`, data);
	}

	/**
	 * Send confirmation email
	 *
	 */
	sendConfirmationEmail(currentLang: string, currentDomain: string): Observable<any> {
		const defaultLang = 'es';
		const availableLangs = LANGS;

		const lang = availableLangs.includes(currentLang) ? currentLang : defaultLang;
		const domain = availableLangs.includes(currentDomain) ? currentDomain : defaultLang;

		const data = {
			lang,
			domain
		};
		return this._httpClient.post(`${this._serverUrl}/email/verification-notification`, data);
	}

	updateEmail(data: any): Observable<any> {
		return this._httpClient.patch(`${this._serverUrl}/auth/update_generated_email`, data);
	}

	changePassword(data: any): Observable<any> {
		return this._httpClient.post(`${this._serverUrl}/auth/change_password`, data);
	}

	changeEmail(data: any): Observable<any> {
		return this._httpClient.post(`${this._serverUrl}/auth/change_email_signed_route`, data);
	}

	verifyResetToken(data: any): Observable<any> {
		return this._httpClient.post(`${this._serverUrl}/auth/verify_token`, data);
	}

	verifyEmail(data: any): Observable<any> {
		return this._httpClient.post(`${this._serverUrl}/email/verify`, data);
	}
}
